import * as React from "react";
import Layout from "../components/layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout>
      <Row className="mb-3">
        <Col>
          <StaticImage
            src="../images/img_about.jpg"
            alt=""
            placeholder="blurred"
          />
        </Col>
      </Row>

      <Row className="about">
        <Col>
          <h1>Contact</h1>
          <p>
            Please contact us if you have any questions regarding our
            procedures, our foundation or if you want to apply for a free
            treatment in one of our partner facilities.
          </p>
          <p>
            You can reach us at{" "}
            <a href="mailto:contact@beauty-foundation.com">
              contact@beauty-foundation.com
            </a>
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
